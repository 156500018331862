<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้าราชการครูและบุคลากรทางการศึกษา"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" lg="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" lg="6" class="text-right">
              <v-btn
                block
                large
                color="primary"
                @click.native="college_updateeditCSV()"
              >
                <v-icon>mdi-plus-circle-outline</v-icon>
                <h3>นำเข้าไฟล์รายชื่อ CSV</h3>
              </v-btn>
              <v-btn
                block
                large
                color="primary"
                @click.native="college_updateeditCode()"
              >
                <v-icon>mdi-pencil</v-icon>
                <h3>Update College Code</h3>
              </v-btn>

              <v-btn block color="warning" large @click="CancelRate_work_g()"
                >ยกเลิกข้อมูล อัตรากำลังวิทยาลัยดังกล่าว</v-btn
              >

              <v-btn block color="warning" large @click="ClearCollege()"
                >ยกเลิกสถานศึกษาดำเนินการผิด</v-btn
              >

            </v-col>
          </v-row>
        </v-card>
        <v-data-table
          :headers="headers"
          :items="college_updateedits"
          class="elevation-1"
          :loading="loading"
          :search="search"
        >
          <template v-slot:item.dels="{ item }">
            <v-icon
              color="red"
              @click.stop="college_updateeditDelete(item.id_card)"
            >
              mdi-delete
            </v-icon>
          </template>

          <v-alert slot="no-results" :value="true" color="error" icon="warning">
            Your search for "{{ search }}" found no results.
          </v-alert>
        </v-data-table>
      </base-material-card>

      <!-- V-model csvcollege_updateeditdialog -->
      <v-layout>
        <v-dialog
          v-model="csvcollege_updateeditdialog"
          persistent
          max-width="50%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="green"
              icon="mdi-content-paste"
              title="นำเข้าข้อมูลไฟล์ CSV"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text>
              <v-card>
                <v-card-text>
                  <v-form ref="csvcollege_updateeditdialogform" lazy-validation>
                    <v-container grid-list-md>
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-alert
                            border="left"
                            colored-border
                            type="info"
                            elevation="2"
                          >
                            <h2>
                              นำเข้าข้อมูลไฟล์ CSV
                              <input type="file" ref="file2" id="file2" />
                            </h2>
                          </v-alert>
                        </v-col>
                        <v-col cols="12" md="12" class="text-center">
                          <v-btn
                            large
                            color="green"
                            @click.stop="csvcollege_updateeditSubmit()"
                          >
                            <v-icon>mdi-book-plus</v-icon>
                            นำเข้า</v-btn
                          ><v-btn
                            large
                            color="info"
                            to="/admin/college_updateedit_import/"
                            target="_blank"
                          >
                            <v-icon>mdi-exit-to-app</v-icon
                            >ส่งออกไฟล์บันทึกข้อมูล</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-row>
              <v-col cols="12" md="12" class="text-right">
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  large
                  color="grey"
                  @click.stop="csvcollege_updateeditdialog = false"
                >
                  <v-icon dark>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model deletecollege_updateeditdialog -->
      <v-layout>
        <v-dialog
          v-model="deletecollege_updateeditdialog"
          persistent
          max-width="40%"
        >
          <v-card class="mx-auto pa-5">
            <base-material-card
              color="error"
              icon="mdi-delete"
              title="ลบข้อมูลผู้ใช้"
              class="px-5 py-3 text_google"
            >
            </base-material-card>

            <v-card-text class="text_google">
              <v-card>
                <v-card-text>
                  <v-form ref="deletecollege_updateeditform" lazy-validation>
                    <v-container grid-list-md>
                      <v-layout wrap>
                        <v-flex xs12>
                          ยืนยันการลบข้อมูลผู้ใช้
                          {{ editcollege_updateedit.college_code }}
                          <span v-if="editcollege_updateedit.college_name">{{
                            editcollege_updateedit.college_name
                          }}</span>
                          <div>
                            {{ editcollege_updateedit.title_s
                            }}{{ editcollege_updateedit.frist_name }}
                            {{ editcollege_updateedit.last_name }}
                          </div>
                          <div>
                            {{ editcollege_updateedit.position_name }} :
                            {{ editcollege_updateedit.id_position }}
                          </div>
                        </v-flex>
                        <v-text-field
                          filled
                          rounded
                          type="password"
                          label="Password"
                          :rules="[v => v === '0612698358']"
                        >
                        </v-text-field>
                      </v-layout>
                    </v-container>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="deletecollege_updateeditdialog = false"
                ><v-icon dark>mdi-close</v-icon>ยกเลิก</v-btn
              >
              <v-btn
                large
                color="red darken-3"
                @click.stop="deletecollege_updateeditSubmit()"
                dark
              >
                <v-icon dark>mdi-delete</v-icon>&nbsp;ลบ
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>
    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      addcollege_updateeditdialog: false,
      editcollege_updateeditdialog: false,
      deletecollege_updateeditdialog: false,
      csvcollege_updateeditdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      college_updateedits: [],
      addcollege_updateedit: {},
      editcollege_updateedit: {},
      search: "",
      pagination: {},
      headers: [
        {
          text: "วิทยาลัย",
          width: "20%",
          align: "left",
          value: "college_name"
        },
        { text: "รหัสสถานศึกษา", align: "center", value: "college_code" },
        {
          text: "ลบ",
          align: "center",
          value: "dels",
          icon: "mdi-file-document-edit"
        }
      ],

      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      data_syslog: {},
      upcsv_file: {}
    };
  },

  async mounted() {
    await this.college_updateeditQueryAll();
  },

  methods: {
    async college_updateeditQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("college_updateedit.php", {
          ApiKey: this.ApiKey
        })
        .finally(() => (this.loading = false));
      this.college_updateedits = result.data;
    },

    async searchWait() {
      this.loading = true;
      let result = await this.$http
        .post("college_updateedit.php", {
          ApiKey: this.ApiKey,
          status_appove: "wait"
        })
        .finally(() => (this.loading = false));
      this.college_updateedits = result.data;
    },

    async college_updateeditAdd() {
      this.addcollege_updateedit = {};
      this.addcollege_updateeditdialog = true;
    },

    async college_updateeditCSV() {
      this.csvcollege_updateeditdialog = true;
    },

    async college_updateeditCode() {
      let data = {
        ApiKey: this.ApiKey
      };
      let result = await this.$http.post(
        "college_updateedit.updateCollegecode.admin.php",
        data
      );
      if (result.data.status == true) {
        this.college_updateedit = result.data;
        Swal.fire({
            icon: 'success',
            title: 'แก้ไขข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
        this.college_updateeditQueryAll();
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.data_syslog.ApiKey = this.ApiKey;
        this.data_syslog.user_account = userSession.user_name;
        this.data_syslog.event_log = "update";
        this.data_syslog.page_log = "college_updateedit";
        this.data_syslog.table_log = "college_updateedit";
        this.data_syslog.detail_log = this.college_updateedit.id_card;
        this.data_syslog.date_times = this.date_today_log;
        await this.$http.post("data_syslog.insert.php", this.data_syslog);
      } else {
        Swal.fire({
            icon: 'warning',
            title: 'แก้ไขข้อมูลผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
      }
    },

    async college_updateeditDelete(id_card) {
      let result = await this.$http.post("college_updateedit.php", {
        ApiKey: this.ApiKey,
        id_card: id_card
      });
      this.editcollege_updateedit = result.data;
      this.deletecollege_updateeditdialog = true;
    },

    async deletecollege_updateeditSubmit() {
      if (this.$refs.deletecollege_updateeditform.validate()) {
        this.editcollege_updateedit.ApiKey = this.ApiKey;
        let result;
        result = await this.$http.post(
          "college_updateedit.delete.php",
          this.editcollege_updateedit
        );
        
        if (result.data.status == true) {
          this.college_updateedit = result.data;
          Swal.fire({
            icon: 'success',
            title: 'ดำเนินการลบข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
          this.college_updateeditQueryAll();
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "delete";
          this.data_syslog.page_log = "college_updateedit";
          this.data_syslog.table_log = "college_updateedit";
          this.data_syslog.detail_log = this.college_updateedit.id_card;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'ดำเนินการลบผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
        }
        this.deletecollege_updateeditdialog = false;
      }
    },
    async csvcollege_updateeditSubmit() {
      let result = "";
      let uploaded = null;
      if (this.$refs.file2.files[0]) {
        let formData = new FormData();
        let filename = this.time_stamp + ".college_updateedit.csv";
        formData.append("file", this.$refs.file2.files[0]);
        formData.append("filename", "../HRvecfiles/" + filename);
        formData.append("ApiKey", this.ApiKey);
        result = await this.$http.post("college_updateeditcsv.php", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });
        uploaded = true;
      } else {
        uploaded = false;
      }
      if (result.data.status == true) {
        this.college_updateedit = result.data;
        Swal.fire({
            icon: 'success',
            title: 'ดำเนินการบันทึกข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
        this.college_updateeditQueryAll();
      } else {
        Swal.fire({
            icon: 'warning',
            title: 'ดำเนินการผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
        this.college_updateeditQueryAll();
      }
      this.csvcollege_updateeditdialog = false;
    },

    async CancelRate_work_g() {
        let data = {
        ApiKey: this.ApiKey
      };
      let result
       result = await this.$http.post(
        "rate_work_college_ManpowerCancel.php",
        data
      );

        result = await this.$http.post(
        "rate_work_g_ManpowerCancel.php",
        data
      );

        result = await this.$http.post(
        "rate_work_college_cal_room_ManpowerCancel.php",
        data
      );

       result = await this.$http.post(
        "rate_work_sc_ManpowerCancel.php",
        data
      );

      if (result.data.status == true) {
        this.college_updateedit = result.data;
        Swal.fire({
            icon: 'success',
            title: 'แก้ไขข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
        this.college_updateeditQueryAll();
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.data_syslog.ApiKey = this.ApiKey;
        this.data_syslog.user_account = userSession.user_name;
        this.data_syslog.event_log = "update";
        this.data_syslog.page_log = "college_updateedit";
        this.data_syslog.table_log = "college_updateedit";
        this.data_syslog.detail_log = this.college_updateedit.id_card;
        this.data_syslog.date_times = this.date_today_log;
        await this.$http.post("data_syslog.insert.php", this.data_syslog);
      } else {
        Swal.fire({
            icon: 'warning',
            title: 'แก้ไขข้อมูลผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
      }


    },
    async ClearCollege(){
 let data = {
        ApiKey: this.ApiKey
      };
      let result
       result = await this.$http.post(
        "college_updateedit.delete.php",
        data
      );
       if (result.data.status == true) {
        this.college_updateedit = result.data;
        Swal.fire({
            icon: 'success',
            title: 'แก้ไขข้อมูลเรียบร้อย',
            showConfirmButton: false,
            timer: 1500,
          })
        this.college_updateeditQueryAll();
        let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
        this.data_syslog.ApiKey = this.ApiKey;
        this.data_syslog.user_account = userSession.user_name;
        this.data_syslog.event_log = "update";
        this.data_syslog.page_log = "college_updateedit";
        this.data_syslog.table_log = "college_updateedit";
        this.data_syslog.detail_log = this.college_updateedit.id_card;
        this.data_syslog.date_times = this.date_today_log;
        await this.$http.post("data_syslog.insert.php", this.data_syslog);
      } else {
        Swal.fire({
            icon: 'warning',
            title: 'แก้ไขข้อมูลผิดพลาด',
            showConfirmButton: false,
            timer: 1500,
          })
      }


    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },

    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
